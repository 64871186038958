<template>
  <el-form-item label="选择课程" :required="true">
    <select-tree-single ref="selectTree" @parentEvent="getSelId" data-id="0"></select-tree-single>
  </el-form-item>
</template>

<script>
import SelectTreeSingle from "@/components/SelectTreeSingle";
import {getLocalProjectId, UrlEnum} from "../../public/js/common-vue";
export default {
  name: "CourseFormItemSingle",
  components: {SelectTreeSingle},
  data(){
    return {
      courseId:'',
      courses:[]
    }
  },
  methods:{
    getCourseId(){
      return this.courseId;
    },
    getSelId(data){
      this.courseId = data;
    },
    initData(checkedId,categoryIds) {
      this.courseId = checkedId;
      let url = UrlEnum.COURSE_TREE_DROPDOWN + '?sourceId=0&projectId=' + getLocalProjectId() + '&page=1&size=100';
      if(categoryIds != null){
        url += "&categoryIds="+this.categoryIds;
      }
      this.$refs.selectTree.initData(url,checkedId);
    }
  }
}
</script>

<style scoped>

</style>
